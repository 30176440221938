@import url('https://fonts.googleapis.com/css?family=Barlow');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Barlow', sans-serif;
  color: white;
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}
$lila: #D32AA2;
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.9);
$signatureWidth: 200px;
@mixin flex($justify, $align, $direction) {
  display: flex;
  flex-wrap: wrap;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

a:hover {
  transition: all 200ms ease-in;
  color: $lila
}

ul.signatureList {
    margin-top: 20px;
    -moz-column-width: $signatureWidth;
    -moz-column-gap: 10px;
    -webkit-column-width: $signatureWidth;
    -webkit-column-gap: 10px;
    column-width: $signatureWidth;
    column-gap: 10px;
    list-style-type: none;
}
li.signatureList {
  margin-bottom: 5px !important;
}


.displaynone {
  display: none;
}

.letter__text {
  text-align: justify;
  text-justify: auto;
}

.dark-box {
  max-width: 972px;
  margin: 0 auto 2rem;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.85);
  border: 3px solid $lila;
  color: white;
  font-size: 1.2rem;
  line-height: 1.7;

  ul {
    list-style: inside;
  }
}

.title {
  padding: 1em;
  color: white;
  font-size: clamp(1.2rem, 3vw ,2rem);
  text-align: center;
}
@import 'letter';
@import 'home';
@import 'materials';
@import 'events';
@import 'nav';
@import 'footer';
@import 'popup';
.video iframe {
  width: clamp(320px, 96vw, 640px);
  height: clamp(200px, 60vw, 400px);
}

@media screen and (max-width:900px) {
  main {
    padding: 0rem;
  }
  .materials {
    //aligned on top with letters and events
    padding: 0em;
  }
}




