//main: style.scss

.materials {
  //aligned on top with letters and events
  padding: 3em;
}
.press__list {
  list-style-type: square;
  list-style-position: inside;
}

video, iframe {
  width: clamp(200px, 90%, 560px);
  height: clamp(110px, 50%, 315px);
}
.material, .video{
  @include flex (space-around, center, row);
}
.material img {
  width: 400px;
  max-width:100%;
  margin: 10px;
  height:auto;
  vertical-align: top;
  display: inline-block;
}
