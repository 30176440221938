//main: style.scss

.banner {
  @include flex(space-around, center, row);
  background: white;
  margin-bottom: 20px;
  margin-top: 10px;
  span {
    font-size: 3vw;
    color: black;
  }

  .hero__text{
    font-size: 3vw;
  }
  .header__logo {
    height: 100px;
  }

  @media (max-width: 900px) {
    margin-bottom: 10px;
  }
}

main {
  background: url("../img/Illegal.png");
  -o-animation-direction: 100%;
  text-shadow: $text-shadow;

  &__title {
    font-size: clamp(1rem, 3vw ,1.8rem);
  }

  &__text {
    max-width: 972px;
    margin: 2rem auto;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.85);
    border: 1px solid $lila;
    color: white;
    font-size: clamp(1rem, 2vw, 1.2rem);
    line-height: 1.7;
  }
}
main.home{
    background: none;
    background-color: #000;
}

.letter {
  &__title {
    white-space: pre-line;
  }

  &__text {
    white-space: pre-line;
  }
}

.signatories {
  padding: 1rem;
  text-align: center;
  //TODO this was having a strange effect, was it intended? if yes revert
  //background: #141313;
  background: black;

  h3 {
    font-size: 2.1rem;
    color: white;
  }

  p {
    color: $lila;
    font-size: 1.1rem;
  }
}