//main: style.scss
.popup_languages {
  @include flex(space-around, center, column);
  color: black;
  font-size: 1.3rem;
  list-style: none;
  border-radius: 5px;
  .languages__tab {
    cursor: pointer;
    color: black;
    margin: 3px;
  }
}
.popup_languages li:hover {
  color: #ff26a5;
}
