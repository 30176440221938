//main: style.scss

.languages > * {
  padding: 5px;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}

nav {
  background: black;
  padding: 1em;

  ul {
    list-style: none;
    @include flex(space-around, center, row);

    & > li:hover {
      color: $lila;
    }

    & > * {
      color: white;
      font-size: 1.4rem;
      transition: all 250ms ease-in;
    }
  }
}

.route {
  @include flex(space-around, center, row);
  color: white;
  font-size: 1.7rem;
  transition: all 200ms ease-in;

  &.activeRoute {
    color: $lila;
  }
}